import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './img/FriCo-logo.svg'; 
import SimpleReactValidator from "simple-react-validator";
import { Button, Container, Grid, Link, Modal, Paper, Typography } from '@mui/material';
import firebase from "../../config/firebase";
import { saveUserProfileStateTemp, authInfoState, uiSetup } from '../../config/userProfile';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link as RouterLink } from 'react-router-dom';
import GoogleReCaptchaLegal from '../../config/GoogleReCaptchaLegal';

const SignUp = (props) => {

  const initialLoginState = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    captchaError: false,
    disableSubmit: true,
    modalOpen: false,
  };

  const navigate = useNavigate();
  const [userProfileError, setUserProfileError] = useState('');
  const [loginState, setLoginState] = useState(initialLoginState);
  const [allowLogin, setAllowLogin] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    // Fetch the allowLogin state from Firebase
    const fetchAllowLogin = async () => {
      try {
        const dbRef = firebase.firestore().doc('/itu/testing-ahaa');
        const doc = await dbRef.get();
        if (doc.exists) {
          const data = doc.data();
          setAllowLogin(data.allowLogin);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching allowLogin state: ", error);
      }
    };

    fetchAllowLogin();
  }, []);

  const handleSubmit = async () => {
    console.log("Handling");
    if (formRef.current.isFormValid()) {
      // 1. create new user
      // 2. send email verification
      // 3. save details to firestore temp document
      firebase.auth().createUserWithEmailAndPassword(loginState.email, loginState.password)
        .then((resp) => {
          saveUserProfileStateTemp(resp.user, loginState);
          firebase.auth().currentUser.sendEmailVerification();
          navigate("/emailverification");
        })
        .catch((err) => {
          setUserProfileError(String(err));
        });
    } else {
      setUserProfileError('Virheilmoitus: lomakkeen tiedot eivät kelpaa.');
    }
  };

  const formRef = useRef(null);

  const handleModalClose = () => {
    setLoginState({ ...loginState, modalOpen: false });
  };

  const handleChange = (e) => {
    setLoginState({
      ...loginState,
      [e.target.id]: e.target.value,
    });
  };

  return uiSetup("SignUp") && (
    <div>
      <div>
        <Modal open={loginState.modalOpen} onClose={handleModalClose}>
          <div className="center modal-size-signup">
            <h4 className='blue-text'>Lomakkeen tiedoissa on puutteita.</h4><br />
            <button className="btn z-depth-0" onClick={handleModalClose}>Sulje</button>
          </div>
        </Modal>
      </div>

      <ValidatorForm onSubmit={handleSubmit} debounceTime={1000} ref={formRef}>
        <Container fixed={true} maxWidth="xs" style={{ paddingTop: "50px" }}>
          <Paper>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item align="center" style={{ width: "85%" }}>
                <img src={logo} alt="itu logo" />
              </Grid>
              <Grid item align="center">
                <Typography variant="h5">Luo uusi tili:</Typography>
              </Grid>
              <Grid item align="center" style={{ width: '70%' }}>
                <TextValidator type="text" autoComplete="email" name="email" id='email' label="sähköpostiosoite" variant="outlined" fullWidth onChange={handleChange} value={loginState.email}
                  validators={['required', 'isEmail']}
                  errorMessages={['sähköpostiosoite on pakollinen tieto', 'sähköpostiosoite ei kelpaa']}
                  disabled={!allowLogin}
                />
              </Grid>
              <Grid item align="center" style={{ width: '70%' }}>
                <TextValidator type="password" autoComplete="new-password" name='new-password' id='password' label="salasana" variant="outlined" fullWidth onChange={handleChange} value={loginState.password}
                  validators={['required', 'matchRegexp:^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})']}
                  errorMessages={['salasana on pakollinen tieto', 'minimi: 8 merkkiä kirjaimia ja numeroita']}
                  disabled={!allowLogin}
                />
              </Grid>
              <Grid item align="center" style={{ width: '70%' }}>
                <TextValidator type="text" autoComplete="given-name" id='firstName' label="etunimi" variant="outlined" fullWidth onChange={handleChange} value={loginState.firstName}
                  validators={['minStringLength:2']}
                  errorMessages={['minimipituus on kaksi merkkiä']}
                  disabled={!allowLogin}
                />
              </Grid>
              <Grid item align="center" style={{ width: '70%' }}>
                <TextValidator type="text" autoComplete="family-name" id='lastName' label="sukunimi" variant="outlined" fullWidth onChange={handleChange} value={loginState.lastName}
                  validators={['minStringLength:2']}
                  errorMessages={['minimipituus on kaksi merkkiä']}
                  disabled={!allowLogin}
                />
              </Grid>
              <Grid item align="center">
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  color="primary"
                  disabled={!allowLogin}
                >
                  Luo tili
                </Button>
              </Grid>
              <Grid item color="error" align="center" style={{ width: '80%' }}>
                <Typography color="error">
                  {loginState.captchaError ? <span>'Ole hyvä ja lataa sivu uudelleen.'</span> : ''}
                  {userProfileError ? <span>{userProfileError}<br /><br /></span> : ''}
                </Typography>
              </Grid>
              <Grid item align="center" style={{ width: '80%' }}>
                <Typography component="div">
                  <p>Joko sinulla on tili? <Link href="#" component={RouterLink} to="/signin">Kirjaudu sisään</Link></p>
                  <p><Link href="https://www.ahaavision.com" target="_blank">www.ahaavision.com</Link></p>
                </Typography>
                <GoogleReCaptchaLegal />
                <br />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </ValidatorForm>
    </div>
  );
};

export default SignUp;
