import React, { useState, useEffect } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CallToAction } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router';
import { uiSetup } from '../../config/userProfile';
import { newGame } from '../game/Game';
import { makeStyles } from '@mui/styles';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { functions } from '../../config/firebase'; // Ensure firebase is exported from this path
import firebase from '../../config/firebase'; // Ensure firebase is exported from this path
import logo from './img/FriCo-logo-text.svg'; 
import GroupsIcon from '@mui/icons-material/Groups';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { corsGet } from '../../config/helpers';
import ExtensionIcon from '@mui/icons-material/Extension';
import AppsIcon from '@mui/icons-material/Apps';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import gamemasterPdf from '../../assets/pdfs/gamemaster.pdf';
import webmasterPdf from '../../assets/pdfs/webmaster.pdf';
import agreementPdf from '../../assets/pdfs/agreement.pdf';
//import ToggleAllowLoginModal from '../auth/ToggleAllowLoginModal';

import { 
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

import { newSessionInstance } from '../session/Session';

const useStyles = makeStyles((theme) => ({
    page: {
        width: '100%',
        padding: theme.spacing(3),
    },
    root: {
        display: 'flex',
    },
    drawer: {
        width: props => props.drawerWidth,
    },
    drawerPaper: {
        width: props => props.drawerWidth,
    },
    active: {
        background: '#f4f4f4',
        backgroundColor: "#c8e4fb",
    },
    title: {
        padding: theme.spacing(2),
    },
    appBar: {
        width: props => `calc(100% - (${props.drawerWidth}px))`,
        marginLeft: props => props.drawerWidth,
    },
    date: {
        flexGrow: 1
    },
    toolbar: theme.mixins.toolbar,
    avatar: {
        marginLeft: theme.spacing(2)
    }
}));

export default function LeftDrawer({ drawerWidth }) {
    const classes = useStyles({ drawerWidth });
    const navigate = useNavigate();
    const location = useLocation();

    const [allowLogin, setAllowLogin] = useState(false);

    // Fetch the allowLogin state on component mount
    useEffect(() => {
        const fetchAllowLogin = async () => {
            try {
                const dbRef = firebase.firestore().doc("/itu/testing-ahaa");
                const doc = await dbRef.get();
                if (doc.exists) {
                    const data = doc.data();
                    setAllowLogin(data.allowLogin);
                } else {
                    console.error("No such document!");
                }
            } catch (error) {
                console.error("Error fetching allowLogin state: ", error);
            }
        };

        fetchAllowLogin();
    }, []);

    // Function to toggle allowLogin state
    const toggleAllowLogin = async () => {
        try {
            const dbRef = firebase.firestore().doc("/itu/testing-ahaa");

            // Fetch the current state of allowLogin
            const doc = await dbRef.get();
            if (doc.exists) {
                const currentData = doc.data();
                const currentAllowLogin = currentData.allowLogin;

                // Toggle the allowLogin value
                const updatedAllowLogin = !currentAllowLogin;

                // Update Firestore document with the new value
                await dbRef.update({ allowLogin: updatedAllowLogin });

                // Update local state for immediate feedback
                setAllowLogin(updatedAllowLogin);

                console.log("allowLogin successfully toggled to:", updatedAllowLogin);
            } else {
                console.error("Document does not exist!");
            }
        } catch (error) {
            console.error("Error toggling allowLogin state: ", error);
        }
    };

    const menuItems = [
        { 
            visible: uiSetup("Application"),
            divider: false, 
            text: 'Listaa pelit', 
            icon: <FormatListBulletedIcon color="secondary" />, 
            onClick: () => { navigate('/sessionlist') }
        },
        { 
            visible: (location.pathname === '/' || location.pathname === '/sessionlist') && uiSetup("Application"),
            divider: true, 
            text: 'Luo uusi peli', 
            icon: <AddIcon color="secondary" />, 
            onClick: () => { 
                console.log(">>>> Starting "); 
                newSessionInstance(0); 
            },
        },
        { 
            visible: (location.pathname === '/' || location.pathname === '/sessionlist') && uiSetup("Application"),
            divider: true, 
            text: 'Create a new game', 
            icon: <AddIcon color="secondary" />, 
            onClick: () => { 
                console.log(">>>> Starting "); 
                newSessionInstance(1); 
            },
        },
        { 
            visible: (location.pathname === '/' || location.pathname === '/sessionlist') && uiSetup("Application") && uiSetup("AhaaUser"),
            divider: true, 
            text: 'Luo uusi VAJO peli', 
            icon: <AddIcon color="secondary" />, 
            onClick: () => { 
                console.log(">>>> Starting "); 
                newSessionInstance(2); 
            },
        },
        { 
            visible: (location.pathname === '/' || location.pathname === '/sessionlist') && uiSetup("Application") && uiSetup("AhaaUser"),
            divider: true, 
            text: 'Luo uusi VH peli', 
            icon: <AddIcon color="secondary" />, 
            onClick: () => { 
                console.log(">>>> Starting "); 
                newSessionInstance(3); 
            },
        },
        { 
            visible: (location.pathname === '/' || location.pathname === '/sessionlist') && uiSetup("Application") && uiSetup("AhaaUser"),
            divider: true, 
            text: 'Luo uusi OSAO peli', 
            icon: <AddIcon color="secondary" />, 
            onClick: () => { 
                console.log(">>>> Starting "); 
                newSessionInstance(4); 
            },
        },
        { 
            visible: (location.pathname === '/' || location.pathname === '/sessionlist') && uiSetup("Application") && uiSetup("AhaaUser"),
            divider: true, 
            text: `Toggle login (${allowLogin})`, // Dynamic text based on allowLogin
            icon: <AddIcon color="secondary" />, 
            onClick: () => { 
                console.log(">>>> Toggle login "); 
                toggleAllowLogin(); 
            },
        },
        { 
            visible: uiSetup("Application"),
            divider: false, 
            text: 'OHJE: Pelinohjaus', 
            icon: <PictureAsPdfIcon color="secondary"  />, 
            onClick: () => { window.open(gamemasterPdf, '_blank').focus() }
        },        
        { 
            visible: uiSetup("Application"),
            divider: false, 
            text: 'OHJE: Toiminnot', 
            icon: <PictureAsPdfIcon color="secondary" /> , 
            onClick: () => { window.open(webmasterPdf, '_blank').focus() }
        },
        { 
            visible: uiSetup("Application"),
            divider: false, 
            text: 'Käyttösopimus', 
            icon: <PictureAsPdfIcon color="secondary" /> , 
            onClick: () => { window.open(agreementPdf, '_blank').focus() }
        },        
        { 
            visible: false && uiSetup("Application"),
            divider: true, 
            text: 'Luo uusi pelilauta', 
            icon: <AppsIcon color="secondary" />, 
            onClick: () => {
                newGame();
                navigate('/gamelist')
            }
        },
        { 
            visible: false && uiSetup("Application"),
            divider: false, 
            text: 'Listaa peliruudut', // block
            icon: <ExtensionIcon color="secondary" />, 
            onClick: () => { navigate('/gamelist') }
        },
        { 
            visible: false && uiSetup("Application"),
            divider: true, 
            text: 'Uusi peliruutu', 
            icon: <ExtensionIcon color="secondary" />, 
            onClick: () => { navigate('/gamelist') }
        },
        { 
            visible: false && uiSetup("Application"),
            divider: false, 
            text: 'CORS test', 
            icon: <GroupsIcon color="secondary" />, 
            onClick: async () => {
                // NOTE: do not use http:// or https:// in argument
                const corsResponse = await corsGet("/google.com");
                console.log("corsResponse", corsResponse.data);
            }
        },
        { 
            visible: uiSetup("AdminLinks"),
            divider: false, 
            text: 'DEV Test', 
            icon: <CallToAction color="secondary" />, 
            onClick: async () => {
                //
                // let doc = newDoc("games",{id:"just-testing"});
                // duplicateDoc("just-testing");
                // deleteDoc("just-testing");
                // console.log(newDoc);
            }
        },
        { 
            visible: uiSetup("AdminLinks"),
            divider: false, 
            text: 'Luo valvoja', 
            icon: <AdminPanelSettingsIcon color="secondary" />, 
            onClick: async () => {
                const addAdminRole = functions.httpsCallable('addAdminRole');
                addAdminRole({ email: "markku.lahtinen@ahaavision.com" }).then(result => {
                    //addAdminRole({ email: "sulismies@gmail.com" }).then(result => {
                    console.log(result);
                });
            }
        },
    ];

    return (
        <div>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{ paper: classes.drawerPaper }}
                anchor="left"
                PaperProps={{sx: {
                    // Customize PaperProps if needed
                }}}
            >
                <img 
                    src={logo} 
                    style={{ 
                        width: `${drawerWidth - 40}px`, 
                        paddingTop: "20px", 
                        paddingBottom: "20px", 
                        paddingLeft: "20px" 
                    }} 
                    alt="itu logo"
                />                        
                {/* Links/List Section */}
                <List>
                    {menuItems.map((item) => (
                        item.visible && (
                            <ListItem 
                                divider={item.divider}
                                button 
                                key={item.text} 
                                onClick={item.onClick}
                                className={location.pathname === item.path ? classes.active : null}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        )
                    ))}
                </List>
            </Drawer>
        </div>
    );
}
