import React, { useState } from 'react'
import firebase from "../../config/firebase";
import { useRecoilState } from 'recoil';
import { authInfoState, userProfileErrorState } from '../../config/userProfile'; 
import logo from './img/FriCo-logo.svg'; 
import logoTSR from '../../assets/logos/tsr-bw-horizontal.svg'; 
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Button, Container, Grid, Link, Paper, TextField, Typography } from '@mui/material';

const SignIn = () =>  {

  const [userProfileError, setUserProfileError] = useRecoilState(userProfileErrorState);
  const navigate = useNavigate();

  const [signInState,setSignInState] = useState({
      email: '',
      password: ''
    });

  const handleChange = (e) => {
    setSignInState({
      ...signInState,
      [e.target.id]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserProfileError('');
    if(!signInState.password.length){
      setUserProfileError(<p>Salasana puuttuu!</p>);
      return;
    }
    if(!signInState.email.length){
      setUserProfileError(<p>Sähköpostiosoite puuttuu!</p>);
      return;
    }

    firebase.auth().signInWithEmailAndPassword(signInState.email,signInState.password).then((userCredential) => {
      navigate('/sessionlist')
    }).catch((error) => {
      setUserProfileError(error.message);
    });
  }

  const onForgotPassword = (e) => {
    e.preventDefault();
    if(!signInState.email.length){
      setUserProfileError(<p>Sähköpostiosoite puuttuu!</p>);
      return;
    }
    firebase.auth().sendPasswordResetEmail(signInState.email)
    .then(() => {
      setUserProfileError(
        <div>
          <p>Salasanan palautusviesti on lähetetty antamaasi sähköpostiosoitteeseen: {signInState.email}</p>
          <p>Palautusviesti menee suurella todennäköisyydellä sähköpostiohjelmasi roskapostikansioon, muistathan tarkistaa siis viestin perilletulon myös sieltä!</p>
          <p>Ellei kirjautuminen kaikesta huolimatta onnistu, soita Markku Lahtinen p. 040 - 4140 618.</p>
        </div>
      );
    })
    .catch((error) => {
      setUserProfileError(error.code+" : "+error.message);
    });

  }
  
  return (
    <form  onSubmit={handleSubmit}>
      <Container maxWidth="xs" style={{paddingTop: "20px"}} >
        <Paper elevation={0}>
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1} >
            <Grid item align="center" style={{width:"65%"}}>
              <img src={logo} alt="itu logo"/>
            </Grid >
            <Grid item align="center" style={{ width: '70%' }}>
              <TextField type="text" autoComplete="emaik" id='email' label="sähköpostiosoite"  variant="outlined" fullWidth onChange={handleChange} size="small"/>
            </Grid >
            <Grid item align="center" style={{ width: '70%' }}>
              <TextField type="password" autoComplete="password" id='password' label="salasana" variant="outlined" fullWidth onChange={handleChange} size="small" />
            </Grid >
            <Grid item align="center" >
              <Button 
                type="submit"
                variant="contained" 
                color="primary" 
                size="small"
                >
                Kirjaudu
              </Button>
            </Grid >
            {userProfileError &&
              <Grid item color="error" align="center" style={{ width: '80%' }}>
                      <Typography color="error" component="div"><div>{userProfileError}</div></Typography> 
              </Grid >
            } 
            <Grid item align="center" style={{ width: '80%', padding:"40px 0px 10px 0px" }} >
              <Typography  component="p">
                Lähetä salasanan <Link href="#" onClick={onForgotPassword} >palautuslinkki</Link> <br/>
              </Typography>
            </Grid >
            <Grid item align="center" style={{width:"45%", padding:"20px 0px 40px 0px"}}>
              <Typography  component="p">
                <Link href="https://www.ahaavision.com" target="_blank"  >www.ahaavision.com</Link>
              </Typography>
            </Grid >
            <Grid item align="center" style={{width:"45%", paddingBottom:"20px"}}>
              <img src={logoTSR} alt="tsr logo"/>
            </Grid >
          </Grid>
        </Paper>
      </Container>
    </form>
  )
}

export default SignIn


